<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="付款单号">
              <a-input v-model="queryParam.serial_num"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="报价编号">
              <a-input v-model="queryParam.quota_num"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="接单日期">
              <a-range-picker @change="onDateChange" v-model="defaultDate" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-model-item label="订单类型">
              <a-select
                allowClear
                placeholder="请选择订单类型"
                style="width: 100%"
                v-model="queryParam['order_kind']"
                @change="$refs.table.refresh(true)"
              >
                <a-select-option v-for="op in orderKindOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="供应商">
              <a-input v-model="queryParam.customer_name"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用状态">
              <a-select
                allowClear
                placeholder="请选择费用状态"
                style="width: 100%"
                v-model="queryParam.fee_status"
                mode="multiple"
              >
                <a-select-option v-for="op in feeStatusList" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="审核状态">
              <a-select
                allowClear
                placeholder="请选择审核状态"
                style="width: 100%"
                v-model="queryParam.reviewed_status"
                @change="$refs.table.refresh(true)"
              >
                <a-select-option v-for="item in reviewedStatusData" :key="item.value">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="审核人">
              <a-input v-model="queryParam.reviewed_user_name" allowClear />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="审核日期">
              <a-range-picker @change="reviewedDateChange" />
            </a-form-item>
          </a-col> -->
          <a-col v-bind="GLOBAL.queryColSpan">
            <div class="table-operator">
              <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
              <!-- <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleReviewed('basic_audit')">审核</a-button>
              <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleReviewed('undo_audit')">反审核</a-button> -->
              <a-button type="primary" @click="exportSheet">导出</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data="loadData">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <!--          <a @click="printDebitSheet(record, index)" style="margin-right:10px">查看</a>-->
          <!--          <a v-if="record.confirm_status === 0" @click="confirmPayment(record, index)" style="margin-right:10px">业务员确认</a>-->
          <!--          <a v-else-if="record.confirm_status === 1" @click="confirmPayment(record, index)" style="margin-right:10px">经理确认</a>-->
          <a v-if="(typeof record.audit_id) === 'undefined'" @click="handleApplyBizAudit(record, index)" style="margin-right:10px">商务审核</a>
          <a v-if="record.fee_status === 4" @click="handleApplyBizAudit(record, index)" style="margin-right:10px">重新申请</a>
          <template v-if="(typeof record.audit_id) !== 'undefined'">
            <a @click="printPayBillSheet(record, index)" style="margin-right:10px">付款单</a>
          </template>
          <a v-if="record.is_advance === 2 && record.flag !== 1" @click="handleBindInvoice(record, index)" style="margin-right:10px">关联发票号</a>
          <a @click="handleDelete('row', record)" style="margin-right:10px">删除</a>
        </template>
      </span>
      <div slot="feeStatusItem" slot-scope="text, record">
        <a-tag :color="feeStatusColor(record.fee_status)" style="margin-bottom:2px;">
          {{ GLOBAL.feeStatusMaps[record.fee_status] ? GLOBAL.feeStatusMaps[record.fee_status].name : '未提交' }}
        </a-tag>
      </div>
      <div slot="feeItem" slot-scope="text, record">
        <div v-for="item in record.fees" :key="item.id">
          <span v-if="item.pay_target_name">{{ item.pay_target_name }}{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
          <span v-else>{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}</span>
        </div>
      </div>
      <div slot="invoiceItem" slot-scope="text, record">
        <div v-for="item in record.pay_invoices" :key="item.id">
          <span>发票号：{{ item.invoice_number }}税率：{{ item.tax_rate }}</span>
        </div>
      </div>
      <!-- <span slot="reviewedInfo" slot-scope="text">
        <a-tag :color="text === 0 ? 'orange' : 'green'">
          {{ text === 0 ? '未审核' : '已审核' }}
        </a-tag>
      </span> -->
    </s-table>
    <a-modal
      :visible="applyInvoiceVisible"
      :confirmLoading="applyInvoiceConfirmLoading"
      title="开票申请"
      :width="720"
      @cancel="applyInvoiceCancel"
      @ok="handleApplyFormSubmit"
    >
      <a-form-model layout="vertical" :model="applyInvoiceForm" :rules="applyInvoiceRules" ref="applyInvoiceForm">
        <a-row :gutter="20">
          <div>
            <a-divider orientation="left">订单信息</a-divider>
            <a-col :span="8">
              <a-form-model-item label="委托单位">
                <a-input v-model="bizAuditForm.customer" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="所属部门">
                <a-input v-model="bizAuditForm.department" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="录入人">
                <a-input v-model="bizAuditForm.creator" :disabled="true" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="申请时间">
                <a-input v-model="bizAuditForm.applyTime" :disabled="true" />
              </a-form-model-item>
            </a-col>
          </div>
          <a-divider orientation="left"
          >费用信息
            <a-tag color="#f50" style="margin-left:10px;">合计:{{ totalAmount }}</a-tag>
          </a-divider>
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
            style="padding-left:10px;padding-bottom:10px;"
          >
            全选
          </a-checkbox>
          <a-col :span="24">
            <a-checkbox-group v-model="checkedList" @change="onChange" style="width:100%;">
              <a-row v-for="item in bizAuditForm.fees" :key="item.id">
                <a-alert
                  :message="`业务编号:${item.quotaNum}`"
                  type="info"
                  show-icon
                  style="margin-bottom:10px;"
                />
                <a-col
                  :span="8"
                  v-for="fee in item.feeList"
                  :key="fee.id"
                  style="padding-left:15px;padding-bottom:10px;"
                >
                  <a-checkbox :value="fee.id"> {{ fee.feeName }}:{{ fee.money }}{{ fee.currency_name }} </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-col>
          <div>
            <a-col :span="24">
              <a-form-model-item label="申请备注">
                <a-textarea v-model="applyInvoiceForm.apply_remark"></a-textarea>
              </a-form-model-item>
            </a-col>
            <a-divider orientation="left">开票信息</a-divider>
            <a-col :span="8">
              <a-form-model-item label="委托单位/客户名称" prop="customer_name">
                <a-input v-model="applyInvoiceForm.customer_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="纳税人识别号" prop="tax_no">
                <a-input v-model="applyInvoiceForm.tax_no" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="地址">
                <a-input v-model="applyInvoiceForm.address" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="电话">
                <a-input v-model="applyInvoiceForm.mobile" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="银行卡号">
                <a-select v-model="applyInvoiceForm.bank_card_no" @change="bankAccountChange">
                  <a-select-option
                    :value="item.account"
                    v-for="item in bankAccountOps"
                    :key="item.account">
                    {{ item.account }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="开户银行">
                <a-input v-model="applyInvoiceForm.bank" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="税率" prop="tax_rate">
                <a-select v-model="applyInvoiceForm.tax_rate">
                  <a-select-option :value="item.value" v-for="item in taxRateOps" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </div>
        </a-row>
      </a-form-model>
    </a-modal>
    <a-modal
      :visible="businessAuditFormVisible"
      :confirmLoading="businessAuditConfirmLoading"
      title="商务审核申请"
      :width="720"
      @cancel="billOrverifyCancel"
      @ok="billOrVerifySubmit"
    >
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <div>
          <a-divider orientation="left">基础信息</a-divider>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-model-item label="是否预付" prop="is_advance">
                {{ isAdvance === 1 ? '后付' : '预付' }}
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="付款方式" prop="pay_method">
                <a-select v-model="form.pay_method">
                  <a-select-option :value="item.value" v-for="item in paymentList" :key="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="附件数" prop="appendix_num">
                <a-input suffix="张" v-model="form.appendix_num"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item label="发票号码" prop="invoice_number">
            <a-select
              showSearch
              mode="multiple"
              ref="invoice_number"
              :filter-option="filterOption"
              allowClear
              placeholder="请选择发票号码"
              style="width: 100%"
              v-model="form.invoice_num"
            >
              <a-select-option v-for="op in invoiceRegOps" :key="op.id"
              >发票号:{{ op.invoice_number }}/金额:{{ op.amount }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="合同号" prop="contract_list">
            <a-select
              showSearch
              mode="multiple"
              ref="contract_list"
              :filter-option="filterOption"
              allowClear
              placeholder="请选择合同编号"
              style="width: 100%"
              v-model="form.contract_list"
            >
              <a-select-option v-for="op in leaseInfoList" :key="op.id">
                合同号:{{ op.contract_no }}
                <span v-if="op.is_no_term===0 && !op.contract_termination_date">/截至日期:{{ moment(op.contract_end_date).format('YYYY/MM/DD') }}</span>
                <span v-if="op.is_no_term===0 && op.contract_termination_date">/顺延日期:{{ moment(op.contract_termination_date).format('YYYY/MM/DD') }}</span>
                / {{ op.customer_info.customer_cn_name }}
                <span v-if="op.credit_period">/ 信用期：{{ op.credit_period }}天</span>
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
        <a-divider orientation="left">费用信息
          <a-tag color="#f50" style="margin-left:10px;">合计:{{ totalAmount }}</a-tag>
        </a-divider>
        <a-row>
          <a-row v-for="item in feeOrderList" :key="item.id">
            <a-alert :message="`订单编号:${item.quotaNum}`" type="info" show-icon style="margin-bottom:10px;" />
            <a-col :span="24" v-for="fee in item.feeList" :key="fee.id" style="padding-left:15px;padding-bottom:10px;">
              <span>{{ fee.pay_target_name }} {{ fee.feeName }}:{{ fee.money }}{{ fee.currency_name }}</span>
            </a-col>
          </a-row>
        </a-row>
        <div>
          <a-form-model-item label="申请备注" prop="apply_remark">
            <a-textarea v-model="form.apply_remark"></a-textarea>
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-modal>

    <a-modal
      :visible="blanceFeeVisible"
      :confirmLoading="blanceFeeLoading"
      title="编辑付款对账单"
      :width="1200"
      @cancel="blanceFeeCancel"
      @ok="blanceFeeSubmit"
    >
      <!--      已经保存到付款结算单的费用-->
      <a-card title="付款对账单费用" style="margin-bottom: 10px">
        <div class="balanceTitle">报价编号:{{ balanceRecord.quotaNumArr }}</div>
        <a-row>
          <a-col :span="12">
            <div class="balanceTitle">应收费用</div>
            <div v-for="(item, index) in balanceRecord.charge_fees" :key="item.id">{{ item.fee_name }}/{{ item.money }}/{{ item.currency_name }}<a-button type="link" @click="delBalanceFee(index,'charge')">删除</a-button></div>
          </a-col>
          <a-col :span="12">
            <div class="balanceTitle">应付费用</div>
            <div v-for="(item, index) in balanceRecord.pay_fees" :key="item.id">{{ item.fee_name }}/{{ item.pay_target_name }}/{{ item.money }}/{{ item.currency_name }}<a-button type="link" @click="delBalanceFee(index,'pay')">删除</a-button></div>
          </a-col>
        </a-row>
      </a-card>
      <a-card title="订单费用">
        <a-checkbox-group @change="balanceFeeChange" v-model="this.balanceFeeIds" style="width: 100%">
          <a-row v-for="item in balanceData" :key="item.id">
            <div class="balanceTitle">报价编号:{{ item.quota_num }}</div>
            <a-col :span="12">
              <div class="balanceTitle">应收费用</div>
              <a-checkbox v-for="fee in item.charge_fees" :key="fee.id" :value="fee.id">
                {{ fee.fee_name }}/{{ fee.money }}/{{ fee.currency_name }}
              </a-checkbox>
            </a-col>
            <a-col :span="12">
              <div class="balanceTitle">应付费用</div>
              <a-checkbox v-for="fee in item.pay_fees" :key="fee.id" :value="fee.id">
                {{ fee.fee_name }}/{{ fee.pay_target_name }}/{{ fee.money }}/{{ fee.currency_name }}
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-card>
    </a-modal>

    <a-modal
      :visible="printPayStatementsBillModel"
      :confirmLoading="printPayStatementsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款对账单"
      @cancel="printPayStatementsBillModel = false"
    >
      <div id="printStatementsPayBill">
        <pay-statement-bill
          :orderData="printData"
          :type="orderType"
        >
        </pay-statement-bill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printStatementsPayBtn"
                  v-print="printStatementsPayBill">确认打印</a-button>
        <a-button type="primary" @click="printPayStatementsBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="printPayBillModel"
      :confirmLoading="printPayBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款单"
      @cancel="printPayBillModel = false"
    >
      <div id="printPayBill">
        <fms-pay-bill
          :orderList="printPayBillResult"
          :orderData="printPayBillRecord"
        >
        </fms-pay-bill>
      </div>
      <template slot="footer">
        <a-button
          id="printPayBillBtn"
          key="submit"
          type="primary"
          v-print="printPayBill">确认打印
        </a-button>
        <a-button type="primary" @click="printPayBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="invoiceModel"
      :confirmLoading="invoiceModelLoading"
      :width="720"
      :maskClosable="false"
      title="关联发票"
      @cancel="invoiceModel = false"
      @ok="handleBindInvoiceSubmit"
    >
      <bind-invoice ref="bindInvoice" />
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { delItmsPayBill,
getItmsPayBillById,
getItmsPayBillFunc,
ItmsFinanceFunc,
updateItmsPayBill,
batchReviewedPayment,
batchReverReviewedPayment,
exportItmsPaymentSheet
} from '@/api/itms'
import { filterOption } from '@/utils/util'
import { getCommonNoPage, getCustomerInfo, getLeaseInfoListByDepartment, downloadExcel } from '@/api/common'
import moment from 'moment'
import { fmsBizAuditFunc, getFmsInfoDetail, getchargeTax } from '@/api/fms'
import itmsBill from '@/views/fms/bill/FmsItmsBill'
import payStatementBill from '@/views/components/PayStatementBill'
import fmsPayBill from '@/views/fms/bill/FmsPayBill'
import bindInvoice from '@/views/components/BindInvoice'

export default {
  name: 'ItmsPayBill',
  components: {
    STable,
    itmsBill,
    payStatementBill,
    fmsPayBill,
    bindInvoice
  },
  data() {
    return {
      balanceData: [],
      checkedBalanceList: [],
      balanceRecord: {},
      blanceFeeVisible: false,
      blanceFeeLoading: false,
      invoiceModel: false,
      invoiceModelLoading: false,
      queryParam: {},
      feeStatusList: [
        { value: 1, name: `待审核` },
        { value: 2, name: `业务已审核` },
        { value: 3, name: `商务待审核` },
        { value: 4, name: `商务审核被驳回` },
        { value: 9, name: '商务已审核(待付款)' },
        { value: 13, name: '已付款' }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '付款单号',
          dataIndex: 'payment_num'
        },
        {
          title: '付款状态',
          dataIndex: 'fee_status',
          scopedSlots: { customRender: 'feeStatusItem' }
        },
        // {
        //   title: '审核状态',
        //   dataIndex: 'reviewed_status',
        //   scopedSlots: { customRender: 'reviewedInfo' }
        // },
        {
          title: '报价编号',
          dataIndex: 'quota_nums',
          customRender: (text) => {
            return text.join('\n')
          },
          width: 150
        },
        {
          title: '接单日期',
          dataIndex: 'receiving_order_dates',
          width: 130,
          customRender: (text) => {
            if (typeof text !== 'undefined') {
              const names = text.map(i => {
                return moment(i).format('YYYY/MM/DD')
              })
              return names.join('\n')
            } else {
              return ''
            }
          }
        },
        {
          title: '业务类型',
          dataIndex: 'order_type'
        },
        {
          title: '供应商',
          dataIndex: 'customer_name'
        },
        {
          title: '币种',
          dataIndex: 'currency_name'
        },
        {
          title: '是否预付',
          dataIndex: 'is_advance',
          customRender: text => {
            return text === 1 ? '后付' : '预付'
          }
        },
        {
          title: '付款方式',
          dataIndex: 'pay_method',
          customRender: text => {
            return this.paymentMap[text] ? this.paymentMap[text].name : ''
          }
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '付款费用',
          dataIndex: 'fees',
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '附件数',
          dataIndex: 'appendix_num'
        },
        {
          title: '发票信息',
          dataIndex: 'pay_invoices',
          scopedSlots: { customRender: 'invoiceItem' }
        },
        {
          title: '创建人',
          dataIndex: 'create_name'
        },
        // {
        //   title: '审核人',
        //   dataIndex: 'reviewed_user_name'
        // },
        // {
        //   title: '审核时间',
        //   dataIndex: 'reviewed_date',
        //   customRender: (text) => {
        //     return text ? moment(text).format('YYYY/MM/DD') : null
        //   }
        // },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: parameter => {
        return getItmsPayBillFunc('get_itms_payment_page', Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      businessAuditFormVisible: false,
      businessAuditConfirmLoading: false,
      leaseInfoList: [],
      defaultDate: [],
      selectedRowKeys: [],
      taxRateOps: [],
      applyInvoiceVisible: false,
      applyInvoiceConfirmLoading: false,
      indeterminate: false,
      checkAll: true,
      totalAmount: '',
      selectedRows: [],
      checkedList: [],
      feeOrderList: [],
      bankAccountOps: [],
      invoiceRegOps: [],
      isAdvance: null,
      // 商务审核申请form
      form: {
        pay_method: '',
        appendix_num: null,
        apply_remark: '',
        invoice_num: []
      },
      // 开票申请form
      applyInvoiceForm: {
        apply_remark: '',
        customer_name: '',
        tax_no: '',
        address: '',
        mobile: '',
        bank: '',
        bank_card_no: '',
        remark: ''
      },
      bizAuditForm: {
        audit_record_id: '',
        customer: '',
        department: '国贸部',
        creator: '',
        applyTime: moment().format('L'),
        fees: null,
        total: null
      },
      paymentMap: {
        1: { value: 1, name: '转账' },
        2: { value: 2, name: '支票' },
        3: { value: 3, name: '代付' },
        4: { value: 4, name: '打卡' },
        5: { value: 5, name: '现金' },
        6: { value: 6, name: '内部结算' }
      },
      paymentList: [
        {
          value: 1, name: '转账'
        },
        {
          value: 2, name: '支票'
        },
        {
          value: 3, name: '代付'
        },
        {
          value: 4, name: '打卡'
        },
        {
          value: 5, name: '现金'
        },
        {
          value: 6, name: '内部结算'
        }
      ],
      applyInvoiceRules: {
        customer_name: [{ required: true, message: '请输入委托单位/客户名称', trigger: 'blur' }],
        tax_no: [{ required: true, message: '请输入纳税人识别号', trigger: 'blur' }],
        tax_rate: [{ required: true, message: '请选择税率', trigger: 'change' }]
      },
      rules: {
        apply_remark: [{ required: true, message: '请输入申请备注', trigger: 'blur' }],
        pay_method: [{ required: true, message: '请选择付款方式', trigger: 'change' }]
      },
      balanceFeeIds: [],
      printItmsBillModel: false,
      printItmsBillModelLoading: false,
      printItmsBill: {
        id: 'printItmsBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printPayStatementsBillModel: false,
      printPayStatementsBillModelLoading: false,
      printStatementsPayBill: {
        id: 'printStatementsPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printPayBillModel: false,
      printPayBillModelLoading: false,
      printPayBill: {
        id: 'printPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printData: [],
      orderType: '国贸部',
      payment_id: '',
      audit_id: null,
      printPayBillResult: [],
      printPayBillRecord: {},
      auditId: null,
      feeStatusMaps: {
        1: { value: 1, name: '待审核', color: '#faad14' },
        2: { value: 2, name: '业务已审核', color: '#1890ff' },
        3: { value: 3, name: '商务待审核', color: '#4cd964' },
        4: { value: 4, name: '商务审核被驳回', color: '#ff4d4f' },
        9: { value: 9, name: '商务已审核(待付款)', color: '#e7ffec' },
        13: { value: 13, name: '已付款', color: '#e7ffec' }
      },
      orderKindOps: [
        { name: '国贸', value: 1 },
        { name: '奥货', value: 2 },
        { name: '订舱', value: 3 },
        { name: '行政业务', value: 4 },
        { name: '拖运业务', value: 5 }
      ],
      // 审核状态
      reviewedStatusData: [
        { value: 0, name: '未审核' },
        { value: 1, name: '已审核' }
      ]
    }
  },
  methods: {
    moment,
    filterOption,
    exportSheet() {
       this.$confirm({
        title: '确认提示',
        content: '确定要导出表格吗?',
        onOk: () => {
          exportItmsPaymentSheet(this.queryParam).then(res => {
            if (res != null) {
              const fileInfo = { fileName: res }
              downloadExcel(fileInfo).then(downRes => {
                const data = downRes.data
                let fileName = ''
                const contentDisposition = downRes.headers['content-disposition']
                if (contentDisposition) {
                  fileName = decodeURI(escape(contentDisposition.split('=')[1]))
                }
                const url = window.URL.createObjectURL(
                  new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                )
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              })
            }
          })
        }
       })
    },
    // 审核或反审核结算单
    handleReviewed(type) {
      const idList = []
      this.selectedRows.forEach(item => {
        switch (type) {
          case 'basic_audit': // 审核
            if (item.reviewed_status === 0) {
              idList.push(item.id)
            }
            break;
          case 'undo_audit': // 反审核
            if (item.reviewed_status === 1) {
              idList.push(item.id)
            }
            break;
        }
      })
      if (idList.length) {
        let fun
        if (type === 'basic_audit') {
          fun = batchReviewedPayment
        } else {
          fun = batchReverReviewedPayment
        }
        fun({ 'ids': idList }).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '操作成功'
          })
          this.$refs.table.refresh(false)
          this.selectedRows = []
          this.selectedRowKeys = []
        })
      } else {
        this.$message.warning(type === 'basic_audit' ? '请选择未审核的数据' : '请选择已审核的数据')
      }
    },
    // 关联发票model
    handleBindInvoice(record) {
      this.auditId = record.audit_id
      this.invoiceModel = true
    },
    // 提交发票
    handleBindInvoiceSubmit() {
      this.$confirm({
        title: '确认提示',
        content: '确定要关联发票？',
        onOk: () => {
          const data = {
            audit_id: this.auditId,
            invoice_list: this.$refs.bindInvoice.invoice_num
          }
          fmsBizAuditFunc('bind_invoice', data).then((v) => {
            if (v) {
              this.$message.success('关联发票成功')
              this.invoiceModel = false
              this.$refs.table.refresh(false);
              this.$refs.bindInvoice.invoice_num = []
              this.$refs.bindInvoice.initData()
            }
          })
        }
      })
    },
    // 付款对账单
    printDebitSheet(record) {
      getItmsPayBillFunc('payFeeBill', { id: record.id }).then(v => {
        this.printData = v
        this.printPayStatementsBillModel = true
      })
    },
    // 确认付款单
    confirmPayment(record) {
      getItmsPayBillFunc('confirm_payment', { id: record.id }).then(v => {
        if (v) {
          this.$message.success('确认成功~')
          this.$refs.table.refresh(false);
        }
      })
    },
    // 付款单
    printPayBillSheet(record) {
      if (typeof record.audit_id !== 'undefined') {
        getFmsInfoDetail('business_audit', { id: record.audit_id }).then(v => {
          const data = v
          const result = []
          data.forEach(item => {
            const feeList = item.pay_fee_list;
            if (typeof feeList !== 'undefined') {
              feeList.forEach(fee => {
                result.push(fee)
              })
            }
          })
          this.printPayBillResult = result
          record.currency = record.currency_name
          record.customer = record.customer_name
          this.printPayBillRecord = record
          this.printPayBillRecord['apply_remark'] = record.remark
          this.printPayBillRecord['invoice_count'] = record.pay_invoices.length
          this.printPayBillModel = true
        })
      } else {
        this.$message.error('当前付款单没有提交商务审核~')
      }
    },
    balanceFeeChange(value) {
      this.balanceFeeIds = value
    },
    delBalanceFee(index, type) {
      if (type === 'charge') {
        this.balanceRecord.charge_fees.splice(index, 1)
      } else {
        this.balanceRecord.pay_fees.splice(index, 1)
      }
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam.start_date = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam.end_date = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam.start_date = null
        this.queryParam.end_date = null
      }
    },
    reviewedDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['reviewed_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['reviewed_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['reviewed_start_date'] = null
        this.queryParam['reviewed_end_date'] = null
      }
    },
    // 编辑结算单
    handleEdit(record) {
      this.balanceRecord = JSON.parse(JSON.stringify(record))
      getItmsPayBillById('get_itms_fees_by_payment_id', { 'paymentId': record.id }).then(res => {
        this.balanceData = res
      }).finally(() => {
        this.balanceRecord.quotaNumArr = this.balanceRecord.quota_nums.join(',')
        this.blanceFeeVisible = true
      })
    },
    // 删除结算单
    handleDelete(type, record) {
      this.$confirm({
        title: `确认提示`,
        content: `确定要删除？`,
        onOk: () => {
          delItmsPayBill('delete_itms_payment', { 'id': record.id }).then(res => {
            this.$notification['success']({
              message: `提示`,
              description: `删除成功`
            });
          }).finally(() => {
            this.$refs.table.refresh(false);
          });
        }
      });
    },
    // 结算单编辑
    blanceFeeSubmit() {
      const feeIds = []
      // this.balanceRecord.charge_fees.forEach(data => {
      //   feeIds.push(data.id)
      // })
      this.balanceRecord.pay_fees.forEach(data => {
        feeIds.push(data.id)
      })
      const data = {
        'fee_ids': feeIds,
        'id': this.balanceRecord.id,
        'payment_fee_ids': this.balanceFeeIds
      }
      updateItmsPayBill('update_itms_payment', data).then(res => {
        this.$message.success('编辑付款单成功')
        this.balanceFeeIds = []
        this.$refs.table.refresh(false);
      }).finally(() => {
        this.blanceFeeVisible = false
      })
    },
    blanceFeeCancel() {
      this.balanceFeeIds = []
      this.blanceFeeVisible = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    selectItem(record, selected, selectedRows) {
      if (selected) {
        this.selectedRows.push(record)
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].id === record.id) {
            this.selectedRows.splice(index, 1)
          }
        }
      }
    },
    selectAllItem(selected, selectedRows, changeRows) {
      if (selected) {
        for (const item of changeRows) {
          this.selectedRows.push(item)
        }
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          for (const item of changeRows) {
            if (item.id === this.selectedRows[index].id) {
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    handleBillApply(record) {
      this.checkedList = []
      const recordRow = [record]
      this.totalAmount = ''
      const ids = recordRow.map(r => {
        return r.customer_name
      })
      const uniqueIds = Array.from(new Set(ids))
      if (uniqueIds.length > 1) {
        this.$message.warning('请选择同一客户信息')
      } else {
        let flag = false
        const feeMaps = []
        const map = new Map()
        this.selectedRows.forEach(row => {
          const orderFee = {
            quotaNum: row.quota_nums,
            feeList:
              row.charge_fees &&
              row.charge_fees
                .filter(e => e.fee_status === 2 || e.fee_status === 4)
                .map(e => {
                  if (map.has(e.currency_name)) {
                    map.set(e.currency_name, e.money + map.get(e.currency_name))
                  } else {
                    map.set(e.currency_name, e.money)
                  }
                  if (e.fee_status === 2 || e.fee_status === 4) {
                    flag = true
                  }
                  this.checkedList.push(e.id)
                  return {
                    id: e.id,
                    feeName: e.fee_name,
                    money: e.money,
                    currency_name: e.currency_name
                  }
                })
          }
          feeMaps.push(orderFee)
        })
        if (flag) {
          this.bankAccountOps = []
          this.getCustomerOps(recordRow[0].customer_name)
          this.bizAuditForm = {
            ...this.bizAuditForm,
            customer: recordRow[0].customer_name,
            fees: feeMaps,
            creator: recordRow[0].create_name
          }
          this.payment_id = recordRow[0].id
          for (const key of map.keys()) {
            this.totalAmount += map.get(key).toFixed(2) + key + ','
          }
          this.applyInvoiceVisible = true
        } else {
          this.$message.warning('没有可提交的费用,费用状态必须为业务已审核或商务审核被驳回')
        }
      }
    },
    // 提交开票
    handleApplyFormSubmit() {
      this.$refs.applyInvoiceForm.validate(valid => {
        if (valid) {
          const data = {
            ...this.applyInvoiceForm,
            id_list: this.checkedList
          };
          ItmsFinanceFunc('apply_ticket', data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            });
          }).finally(() => {
            this.totalAmount = '';
            this.applyInvoiceVisible = false;
            this.$refs.table.refresh(false);
          });
        }
      })
    },
    handleApplyBizAudit(record) {
      // 提交商务审核
      this.checkedList = []
      this.totalAmount = ''
      this.isAdvance = record.is_advance
      const recordRow = [record]
      const feeMaps = []
      let flag = false
      const map = new Map()
      recordRow.forEach(v => {
        const orderFee = {
          quotaNum: v.quota_nums,
          feeList: v.pay_fees && v.pay_fees
            .filter(e => e.fee_status === 2 || e.fee_status === 4).map(e => {
              if (map.has(e.currency_name)) {
                map.set(e.currency_name, e.money + map.get(e.currency_name))
              } else {
                map.set(e.currency_name, e.money)
              }
              if (e.fee_status === 2 || e.fee_status === 4) {
                flag = true
              }
              this.checkedList.push(e.id)
              return {
                id: e.id,
                feeName: e.fee_name,
                money: e.money,
                currency_name: e.currency_name,
                pay_target_name: e.pay_target_name
              }
            })
        }
        feeMaps.push(orderFee)
      })
      if (flag) {
        this.payment_id = recordRow[0].id
        this.audit_id = recordRow[0].audit_id
        this.feeOrderList = feeMaps
        for (const key of map.keys()) {
          this.totalAmount += map.get(key).toFixed(2) + key + ','
        }
        getCommonNoPage('invoice_registration', {}).then((v) => {
          this.invoiceRegOps = v
        })
        const customerIdList = [record.customer_id]
        getLeaseInfoListByDepartment({ 'department': 2, 'customer_id_list': customerIdList }).then(v => {
          this.leaseInfoList = v
        })
        this.businessAuditFormVisible = true
      } else {
        this.$message.warning('没有可提交的费用,费用状态必须为业务已审核或商务审核被驳回')
      }
    },
    billOrVerifySubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = true;
          ItmsFinanceFunc('business_audit', {
            pay_method: this.form.pay_method,
            invoice_num: this.form.invoice_num,
            apply_remark: this.form.apply_remark,
            contract_list: this.form.contract_list,
            appendix_num: this.form.appendix_num,
            payment_id: this.payment_id,
            id_list: this.checkedList,
            audit_id: this.audit_id
          }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            });
          }).finally(() => {
            this.form.pay_method = ''
            this.form.appendix_num = null
            this.form.apply_remark = ''
            this.form.invoice_num = []
            this.businessAuditFormVisible = false;
            this.$refs.table.refresh(false);
          });
        }
      })
    },
    applyInvoiceCancel() {
      this.applyInvoiceVisible = false;
      this.totalAmount = '';
    },
    billOrverifyCancel() {
      this.businessAuditFormVisible = false;
      this.totalAmount = ''; // 每次关闭弹出框后将会将总金额进行重置
      this.form.pay_method = ''
      this.form.appendix_num = null
      this.form.apply_remark = ''
      this.form.invoice_num = []
    },
    getCustomerOps(name) {
      getCustomerInfo({
        department: null,
        customerName: name
      }).then(res => {
        res.forEach(data => {
          this.applyInvoiceForm.customer_name = typeof data.name !== 'undefined' ? data.name : ''
          this.applyInvoiceForm.tax_no = typeof data.taxNum !== 'undefined' ? data.taxNum : ''
          this.applyInvoiceForm.address = typeof data.street !== 'undefined' ? data.street : ''
          this.applyInvoiceForm.mobile = typeof data.phone !== 'undefined' ? data.phone : ''
          if (typeof data.bankAccount !== 'undefined') {
            this.bankAccountOps = data.bankAccount
            this.applyInvoiceForm.bank_card_no = typeof data.bankAccount[0].account !== 'undefined' ? data.bankAccount[0].account : ''
            this.applyInvoiceForm.bank = typeof data.bankAccount[0].bank_name !== 'undefined' ? data.bankAccount[0].bank_name : ''
          }
        })
      })
    },
    bankAccountChange(data) {
      this.bankAccountOps.forEach(item => {
        if (item.account === data) {
          this.applyInvoiceForm.bank = item.bank_name
        }
      })
    },
    onChange(checkedValues) {
      let count = 0
      this.totalAmount = ''
      const mapItem = new Map()
      for (const item of this.feeOrderList) {
        count += item.feeList.length
        for (let i = 0; i < checkedValues.length; i++) {
          for (let j = 0; j < item.feeList.length; j++) {
            if (checkedValues[i] === item.feeList[j].id) {
              if (mapItem.has(item.feeList[j].currency_name)) {
                mapItem.set(item.feeList[j].currency_name, item.feeList[j].money + mapItem.get(item.feeList[j].currency_name))
              } else {
                mapItem.set(item.feeList[j].currency_name, item.feeList[j].money)
              }
            }
          }
        }
      }
      for (const key of mapItem.keys()) {
        this.totalAmount += mapItem.get(key).toFixed(2) + key + ','
      }
      this.indeterminate = !!this.checkedList.length && this.checkedList.length < count
      this.checkAll = this.checkedList.length === count
    },
    onCheckAllChange(e) {
      this.checkAll = e.target.checked
      this.indeterminate = false
      this.checkedList = []
      this.totalAmount = ''
      const mapList = new Map()
      if (e.target.checked) {
        this.feeOrderList.forEach(item => {
          for (const e of item.feeList) {
            if (mapList.has(e.currency_name)) {
              mapList.set(e.currency_name, e.money + mapList.get(e.currency_name))
            } else {
              mapList.set(e.currency_name, e.money)
            }
            this.checkedList.push(e.id)
          }
        })
        for (const key of mapList.keys()) {
          this.totalAmount += mapList.get(key).toFixed(2) + key + ','
        }
      }
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    }
  },
  created() {
    getchargeTax().then(v => {
      this.taxRateOps = v
    })
  }
}
</script>

<style scoped>

.balanceTitle{
  font-size: 20px;
  font-weight: 600;
}

</style>
